import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { FieldData, FieldTypes, ValidationType } from 'utils/types/formTypes';
import PhoneNumberRegex from 'utils/regex/phoneNumber';
import IBAN from 'iban';
import { MixedSchema } from 'yup';

type FieldValidationSchema = Yup.StringSchema | Yup.NumberSchema | InstanceType<typeof MixedSchema> | Yup.BooleanSchema | any;

const generateFieldValidation = (fieldType: string, validations: ValidationType): FieldValidationSchema => {
  let yupValidation;

  if (fieldType === FieldTypes.text && validations.text) {
    yupValidation = Yup.string();

    const alphaNumRegEx = /^[a-z0-9\s]+$/i
    const bankCodeRegEx = /^[0-9]{3}$/
    const branchCodeRegEx = /^[0-9]{3}$/
    const bsbCodeRegEx = /^\s*(\d{3}(-?|\s*)\d{3})\s*$/
    const cbuRegEx = /^\d{22}$/
    const clabeRegEx = /^\d{18,18}$/
    const ifsCodeRegEx = /^[A-Z]{4}0[A-Z0-9]{6}$/
    const numberRegEx = /^[0-9]+$/
    const routingNumberRegEx = /^[0-9]{3}$/
    const sortCodeRegEx = /^([\d]{2} ?-?){3}$/
    const swiftCodeRegEx = /^[A-Z]{4}[-]{0,1}[A-Z]{2}[-]{0,1}[A-Z0-9]{2}[-]{0,1}[A-Z0-9]{3}$/

    const validateIBAN = (val:string) => {
      if (!val.length) {
        return true
      }
      return IBAN.isValid(val) && alphaNumRegEx.test(val)
    }

    if (validations.text?.length) {
      yupValidation = yupValidation.length(validations.text.length.value, validations.text.length.message)
    }

    if (validations.text.number?.enabled) {
      yupValidation = yupValidation.matches(numberRegEx, validations.text.number.message)
    } else if (validations.text?.sortCode?.enabled) {
      yupValidation = yupValidation.matches(sortCodeRegEx, validations.text.sortCode.message)
    } else if (validations.text?.bankCode?.enabled) {
      yupValidation = yupValidation.matches(bankCodeRegEx, validations.text.bankCode.message)
    } else if (validations.text?.branchCode?.enabled) {
      yupValidation = yupValidation.matches(branchCodeRegEx, validations.text.branchCode.message)
    } else if (validations.text?.bsbCode?.enabled) {
      yupValidation = yupValidation.matches(bsbCodeRegEx, validations.text.bsbCode.message)
    } else if (validations.text?.routingNumber?.enabled) {
      yupValidation = yupValidation.matches(routingNumberRegEx, validations.text.routingNumber.message)
    } else if (validations.text?.clabeCode?.enabled) {
      yupValidation = yupValidation.matches(clabeRegEx, validations.text.clabeCode.message)
    } else if (validations.text?.cbu?.enabled) {
      yupValidation = yupValidation.matches(cbuRegEx, validations.text.cbu.message)
    } else if (validations.text?.ifsCode?.enabled) {
      yupValidation = yupValidation.matches(ifsCodeRegEx, validations.text.ifsCode.message)
    } else if (validations.text?.swiftCode?.enabled) {
      yupValidation = yupValidation.matches(swiftCodeRegEx, validations.text.swiftCode.message)
    } else if (validations.text?.email?.enabled) {
      yupValidation = yupValidation.email(validations.text.email.message)
    } else if (validations.text.phoneNumber?.enabled) {
      yupValidation = yupValidation.matches(PhoneNumberRegex, validations.text.phoneNumber.message)
    } else if (validations.text?.iban?.enabled) {
      yupValidation = yupValidation.test('is-iban', validations.text.iban.message, (value) => validateIBAN(value || ''))
    }
  } else if (fieldType === FieldTypes.number && validations.number) {
    yupValidation = Yup.number();

    if (validations.number.max) {
      yupValidation = yupValidation.max(validations.number.max.value, validations.number.max.message)
    }

    if (validations.number.min) {
      yupValidation = yupValidation.min(validations.number.min.value, validations.number.min.message)
    }
  } else {
    yupValidation = Yup.mixed();
  }

  if (validations.required?.enabled) {
    yupValidation = yupValidation.required(validations.required.message);
  }

  return yupValidation;
}

type ObjectSchemaType = { [key:string]: FieldValidationSchema }

type FormSchemaShape = { [key:string]: FieldValidationSchema | Yup.ObjectSchema<ObjectSchemaType> }

const generateFormValidations = (fields: FieldData[], t: TFunction, requireTos = false) => {
  const validationShape = fields.reduce<FormSchemaShape>((acc, curr) => {
    if (curr.fields?.length) {
      acc[curr.name] = generateFormValidations(curr.fields, t)
    } else if (curr.validations) {
      acc[curr.name] = generateFieldValidation(curr.type, curr.validations);
    }

    return acc
  }, {});

  if (requireTos) {
    validationShape.termsAgreement = Yup.bool().oneOf([true], t('legal.validationText'));
  }

  return Yup.object().shape(validationShape);
}

export default generateFormValidations;
