import { useContext } from 'react';
import { PaymentsContext } from 'contexts/Payments';
import { Spinner } from 'assets/milo';
import { sortPaymentsByDate } from 'utils/helpers/sortPaymentsByDate';
import PaymentsHistoryPlaceholder from './PaymentsHistoryPlaceholder';
import styles from './PaymentsTable.module.css';
import MobileTable from './MobileTable';
import DesktopTable from './DesktopTable';

const PaymentsTable = () => {
  const paymentsContext = useContext(PaymentsContext);

  let completedPayments = paymentsContext?.paymentsData?.completedPayments?.filter((payment) => (
    payment.details.transferSettledAt && payment.details.disbursementAmountActual
  ))

  completedPayments = sortPaymentsByDate(completedPayments);

  if (!paymentsContext?.paymentsData?.currency) {
    return <Spinner className={styles.spinner} />
  }

  return (
    paymentsContext?.paymentsData?.currency && completedPayments && completedPayments.length > 0 ? (
      <div className={styles.paymentsTable}>
        <div className={styles.tableTitle}>Payment history</div>
        <MobileTable completedPayments={completedPayments} />
        <DesktopTable completedPayments={completedPayments} currency={paymentsContext.paymentsData.currency} />
      </div>
    ) : (
      <PaymentsHistoryPlaceholder />
    )
  )
};

export default PaymentsTable;
