type ValuedValidation = {
  value: number,
  message: string
}

type BooleanValidation = {
  enabled: boolean,
  message: string
}

export type ValidationType = {
  number?: {
    max?: ValuedValidation,
    min?: ValuedValidation,
  },
  text?: {
    bankCode?: BooleanValidation,
    branchCode?: BooleanValidation,
    bsbCode?: BooleanValidation,
    cbu?: BooleanValidation,
    clabeCode?: BooleanValidation,
    email?: BooleanValidation,
    iban?: BooleanValidation,
    ifsCode?: BooleanValidation,
    length?: ValuedValidation,
    number?: BooleanValidation,
    phoneNumber?: BooleanValidation,
    routingNumber?: BooleanValidation,
    sortCode?: BooleanValidation,
    swiftCode?: BooleanValidation
  }
  required: BooleanValidation,
}

export enum FieldTypes {
  text = 'text',
  number = 'number',
  group = 'group',
  select = 'select'
}

export interface FieldDataType {
  type: FieldTypes,
  name: string,
  label?: string | null,
  placeholder?: string | null,
  defaultValue?: string | number | null,
  description?: string | null,
  options?: { value: string | number, description: string }[],
  validations?: ValidationType
}

export interface FieldData extends FieldDataType {
  fields?: FieldDataType[] | null;
}

export type FormType = {
  header: string;
  subheader?: string;
  fields: FieldData[];
  initialValues: {
    [key: string]: string | boolean | { [key: string]: string | boolean };
  }
}

export enum PersonaStatusTypes {
  enabled = 'enabled',
  review = 'review',
  disabled = 'disabled'
}
