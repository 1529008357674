import { ReactElement, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import useConfetti from 'hooks/useConfetti';
import usePayments from 'hooks/usePayments';

import Confetti from 'components/Confetti';
import styles from './Dashboard.module.css';
import Notice from 'assets/milo/Notice';
import { NOTICE_TYPES } from 'assets/milo/Notice';

import useQueryWithErrors from 'hooks/useQueryWithErrors';
import { QueryCurrentCompanyMembersResType, GET_CURRENT_COMPANY_MEMBERS_QUERY_NAME, QUERY_CURRENT_COMPANY_MEMBERS } from 'graphql/queries/CompanyMemberQueries';
import { GET_CURRENT_MEMBER_DETAILS_QUERY_NAME, QUERY_CURRENT_MEMBER_DETAILS, QueryCurrentMemberDetailsResType } from 'graphql/queries/MemberDetailQueries';

import { PersonaStatusTypes } from 'utils/types/types';
import classNames from 'classnames';

import { documentsEnabled, personaEnabled } from 'utils/helpers/flags';
import SliderTabBar from 'components/SliderTabBar';
import Payments from 'views/PaymentsView';
import { TABS } from './constants';

const Dashboard = () => {
  usePayments();
  const { t } = useTranslation();
  const { fire, getInstance } = useConfetti();
  const location = useLocation();
  const pathArray = location.pathname.split('/')
  const [searchParams] = useSearchParams();
  const onboardingSuccess = searchParams.get('onboarding_success'); // persona use
  const onboardingComplete = searchParams.get('onboarding_complete');
  const [noticeState, setNoticeState] = useState<{ title?: string, type?: string, status?: string, message?: string | ReactElement, buttonText?:string } | undefined>();
  const noticeMessage = (key: string, options: Record<string, unknown> = {}) => t(`onboarding.${key}`, options);
  const { SUCCESS, WARNING, INFO } = NOTICE_TYPES

  useEffect(() => {
    onboardingComplete === 'true' && fire();
  }, [onboardingComplete]);

  // ** below useQuery hooks are behind the persona feature flag **
  useQueryWithErrors<QueryCurrentMemberDetailsResType>(
    GET_CURRENT_MEMBER_DETAILS_QUERY_NAME,
    QUERY_CURRENT_MEMBER_DETAILS,
    {
      skip: !(personaEnabled() && (onboardingSuccess !== 'true')),
      onCompleted: (data) => {
        const { currentMemberDetails: { accountStatus } } = data
        if (accountStatus?.includes(PersonaStatusTypes.disabled)) {
          setNoticeState({
            title: t('onboarding.failed'),
            type: WARNING,
            status: 'unsuccessful',
            message: (
              <>
                <Trans i18nKey={'onboarding.failedMsg'}>
                  <a
                    href = 'mailto: contractor-support@justworks.com'
                    target='_blank'
                    className={classNames('link')}
                  >
                    contractor-support@justworks.com
                  </a>
                </Trans>
              </>
            )
          })
        } else if (accountStatus?.includes(PersonaStatusTypes.review)) {
          setNoticeState({
            title: t('onboarding.review'),
            type: INFO,
            status: 'pending',
            message: noticeMessage('reviewMsg')
          })
        }
      },
      fetchPolicy: 'no-cache'
    }
  )

  useQueryWithErrors<QueryCurrentCompanyMembersResType>(
    GET_CURRENT_COMPANY_MEMBERS_QUERY_NAME,
    QUERY_CURRENT_COMPANY_MEMBERS,
    {
      skip: !(personaEnabled() && (onboardingSuccess === 'true')),
      onCompleted: (data) => {
        const latestCompanyMember = data.currentCompanyMembers.filter((cm) => (
          cm.details.status !== 'inactive'
        )).sort((cm1, cm2) => (
          (new Date(cm2.details.createdAt)).valueOf() - (new Date(cm1.details.createdAt)).valueOf()
        ));

        const companyName = latestCompanyMember[0].company.details.name
        fire();
        setNoticeState({
          title: t('onboarding.success'),
          type: SUCCESS,
          message: noticeMessage('successMsg', { companyName: companyName }),
          buttonText: t('onboarding.celebrate')
        })
      },
      fetchPolicy: 'no-cache'
    }
  )

  return (
    <>
      {
        noticeState?.type && (
          <Notice
            title={noticeState.title}
            type={noticeState.type}
            status={noticeState.status}
            buttonText={noticeState.buttonText}
            fireConfetti={fire}
            className={pathArray.includes('documents') ? 'display-relative' : 'display-absolute'}
          >
            {noticeState.message}
          </Notice>
        )
      }
      <div data-testid='dashboard' style={{ position: 'relative' }}>
        {documentsEnabled() ? <Outlet /> : <Payments />}

        {documentsEnabled() && (
          <div className={styles.floatingSliderTabBarContainer}>
            <SliderTabBar
              floating
              tabs={TABS}
            />
          </div>
        )}
        <Confetti getInstance={getInstance} />
      </div>
    </>
  );
};

export default Dashboard;
