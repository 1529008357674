import styles from './BankAccount.module.css';
import useQueryWithErrors from '../../hooks/useQueryWithErrors';
import {
  CURRENT_BANK_ACCOUNT_QUERY_NAME, CURRENT_BANK_ACCOUNT_QUERY,
  QueryCurrentBankAccountResType
} from '../../graphql/queries/BankAccountQueries';
import BankAccountForm from '../../components/Forms/BankAccountForm';
import { useContext, useEffect, useState } from 'react';
import { Button, Spinner } from 'assets/milo';
import { PencilIcon } from '../../components/Icons';
import { SupportedCountriesContext } from '../../contexts/SupportedCountries';
import { AlertTypes, useAppAlerts } from '../../contexts/AppAlerts';
import { useTranslation } from 'react-i18next';
import { bankAccountTypeEnabled } from 'utils/helpers/flags';
import { useLocation, useNavigate } from 'react-router-dom';

const BankAccount = () => {
  const [isEditing, setIsEditing] = useState(false)
  const { supportedCountryMap } = useContext(SupportedCountriesContext);
  const { pushAlerts } = useAppAlerts();
  const { t } = useTranslation();
  const { data: bankAccountData, loading, refetch } = useQueryWithErrors<QueryCurrentBankAccountResType>(
    CURRENT_BANK_ACCOUNT_QUERY_NAME,
    CURRENT_BANK_ACCOUNT_QUERY,
    {
      // Ensure we're getting fresh data
      fetchPolicy: 'no-cache'
    }
  )
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname.endsWith('edit')) {
      setIsEditing(true)
    } else {
      setIsEditing(false)
    }
  }, [location.pathname])

  const completeUpdate = () => {
    navigate('/bank-account')
    refetch().then(() => {
      const alert = { message: t('bankAccount.updateSuccess'), type: AlertTypes.Success }
      pushAlerts([alert])
    })
  }

  if (loading) { return <Spinner variation='black' /> }

  const renderEditMode = () => {
    return (
      <BankAccountForm
        currentBankAccount={bankAccountData}
        setIsEditing={setIsEditing}
        onBack={() => navigate('/bank-account')}
        onCompleted={completeUpdate}
        countryCode={bankAccountData?.currentBankAccount?.details?.country}
      />
    )
  }

  const renderView = () => {
    const accountType = bankAccountData?.currentBankAccount?.details?.accountType
    // @ts-ignore
    const country = supportedCountryMap[bankAccountData.currentBankAccount.details.country]
    return (
      <div className={styles.cardContent}>
        <div className={styles.bankNameContainer}>
          <label className={styles.bankName}>{bankAccountData?.currentBankAccount?.nickname}</label>
          <Button
            className={styles.editButton}
            type={'frameless'}
            onClick={() => navigate('/bank-account/edit')}
          >
            <PencilIcon/>
            <span>Edit</span>
          </Button>
        </div>
        <div>{country.emojiFlag} {country.name}</div>
        <div className={styles.divider}></div>

        <div>
          <div className={styles.contentLabelContainer}>
            <label className={styles.contentLabel}>Currency</label>
            <div className={styles.currency}>{bankAccountData?.currentBankAccount?.details?.currency.toUpperCase()}</div>
          </div>

          <div className={bankAccountTypeEnabled() ? styles.contentLabelContainer : undefined}>
            <label className={styles.contentLabel}>Account number</label>
            <div data-dd-privacy='mask'>{bankAccountData?.currentBankAccount?.redactedAccountNumber}</div>
          </div>

          {bankAccountTypeEnabled() && accountType && (
            <div>
              <label className={styles.contentLabel}>Account type</label>
              <div data-dd-privacy='mask'>{t(`bankAccount.${accountType}`)}</div>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div data-testid='bank-account-view'>
      <div className={styles.content}>
        <div>
          <h2>Bank account</h2>
          { (isEditing && bankAccountData) ? renderEditMode() : renderView() }
        </div>
      </div>
    </div>
  );
};

export default BankAccount;
