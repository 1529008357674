import { Alert, Button, Dialog, PageHeader } from '@justworkshr/milo-core';
import styles from './Profile.module.css'
import React, { useContext, useState } from 'react';
import ProfileForm from 'components/Forms/ProfileForm';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { ToastContext } from 'contexts/Toast';
import { TOAST_COLOR } from 'components/Toast';

const PROFILE_URL = '/profile';

export const ProfileEdit = () => {
  const Toasts = useContext(ToastContext);
  const { t } = useTranslation();
  const navigate = useNavigate()

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)

  const onSaveChanges = () => {
    Toasts.addToast({
      content: t('profile.changesSaved'),
      type: TOAST_COLOR.SUCCESS
    })
    navigate(PROFILE_URL);
  };

  const onCancelChanges = () => {
    // NOTE: If any extra form resets are needed, here may be a
    // good place to start before navigating away

    setIsCancelModalOpen(false);
    navigate(PROFILE_URL);
  }

  return (
    <div className={styles.container}>
      <PageHeader
        title={t('profile.editProfileHeader')}
        border={false}
      />

      <div className={styles.alert}>
        <Alert
          color='info'
          dismissIconDescription='close alert'
          visible
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          children={[
            <span key={0}>
              Email <a href='mailto:contractor-support@justworks.com'>contractor-support@justworks.com</a> if you need to make additional changes.
            </span>
          ]}
        ></Alert>
      </div>

      <ProfileForm
        onCompleted={onSaveChanges}
        onBack={() => setIsCancelModalOpen(true)}
        submitButtonText={t('profile.saveChangesButton')}
        backButtonText={t('profile.cancelButton')}
        isProfileEditMode={true}
      />

      <Dialog
        title={t('profile.cancelDialogTitle')}
        isOpen={isCancelModalOpen}
        size='sm'
        showCloseButton={false}
        primaryButton={
          <Button onClick={onCancelChanges} variant='filled'>
            {t('profile.cancelDialogPrimaryButton')}
          </Button>
        }
        secondaryButton={
          <Button onClick={() => setIsCancelModalOpen(false)} variant='ghost'>
            {t('profile.cancelDialogSecondaryButton')}
          </Button>
        }
      >
        <Trans i18nKey='profile.cancelDialogContent' />
      </Dialog>
    </div>
  )
};

export default ProfileEdit;
