import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import { PaymentsContext } from 'contexts/Payments';
import { SupportedCountriesContext } from 'contexts/SupportedCountries';
import { Spinner } from 'assets/milo';

import styles from './EarningsBanner.module.css';

const EarningsBanner = () => {
  const paymentsContext = useContext(PaymentsContext);
  const { formatCurrency, currencySymbolMap } = useContext(SupportedCountriesContext);
  const { t } = useTranslation();

  return (
    <div className={styles.earningsBanner}>
      <div className={styles.total}>
      {
        paymentsContext?.paymentsData?.currency ? (
          <>
            {
              (paymentsContext?.paymentsData?.yearToDateTotal || 0) > 0 ? (
                <CountUp
                  end={paymentsContext?.paymentsData?.yearToDateTotal || 0}
                  duration={1.5}
                  formattingFn={(amount) => formatCurrency(
                    amount,
                    paymentsContext?.paymentsData?.currency,
                    currencySymbolMap[paymentsContext?.paymentsData?.currency as string].symbolPrefix
                  )}
                />
              ) : (
                formatCurrency(
                  paymentsContext?.paymentsData?.yearToDateTotal,
                  paymentsContext?.paymentsData?.currency,
                  currencySymbolMap[paymentsContext?.paymentsData?.currency].symbolPrefix
                )
              )
            }
            <span className={styles.currencyLabel}>{paymentsContext?.paymentsData?.currency}</span>
          </>
        ) : (
          <Spinner className={styles.spinner} />
        )
      }
      </div>
      <div className={styles.bannerSubtitle}>
        {t('dashboard.yearToDate')}
      </div>
    </div>
  )
}

export default EarningsBanner;
