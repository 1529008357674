import usePayments from 'hooks/usePayments';

import EarningsBanner from 'components/EarningsBanner';
import PaymentsTable from 'components/PaymentsTable';
import styles from './Payments.module.css';
import IncomingPaymentsTable from 'components/IncomingPaymentsTable';

const Payments = () => {
  usePayments();

  return (
    <>
        <EarningsBanner />
        <div className={styles.content}>
          <IncomingPaymentsTable />
          <PaymentsTable />
        </div>
    </>
  );
};

export default Payments;
