import { gql } from '@apollo/client';

export const GET_ALL_BANK_ACCOUNTS_QUERY_NAME = 'GetAllBankAccounts';

export type QueryAllBankAccountsResType = {
  id: string;
  memberId: string;
  nickname: string;
}

export const CURRENT_BANK_ACCOUNT_QUERY_NAME = 'CurrentBankAccount'

export const CURRENT_BANK_ACCOUNT_QUERY = gql`
  query ${CURRENT_BANK_ACCOUNT_QUERY_NAME} {
    currentBankAccount {
        createdAt
        id
        memberId
        nickname
        updatedAt
        redactedAccountNumber
        details {
          accountType
          accountTypeAcknowledgedOn
          currency
          countryIsoLongName
          country
          institutionNumber
          routingNumber
          transitNumber
        }
    }
}

`

export const QUERY_ALL_BANK_ACCOUNTS = gql`
  query ${GET_ALL_BANK_ACCOUNTS_QUERY_NAME} {
    allBankAccounts {
      id
      nickname
      createdAt
      updatedAt
      details {
          id
          bankAccountId
          accountHolderName
          accountHolderType
          accountType
          accountTypeAcknowledgedOn
          bankName
          country
          currency
          routingNumber
          accountNumber
          fingerprint
          last4
          validAt
          invalidAt
      }
    }
  }
`

export const ONBOARDING_BANK_ACCOUNT_QUERY_NAME = 'onboardingBankAccount';

export type BankAccountInitialValuesResType = {
  nickname: string;
  accountNumber: string;
  accountType: string;
  institutionGroup: {
    institutionNumber: string
    transitNumber: string
  }
}

export type QueryOnboardingBankAccountResType = {
  onboardingBankAccount: {
    initialValues: BankAccountInitialValuesResType
  }
}

export type QueryCurrentBankAccountResType = {
  currentBankAccount: {
    id: string,
    memberId: string,
    nickname: string,
    redactedAccountNumber: string,
    details: {
      country: string,
      currency: string,
      accountType: string
      accountTypeAcknowledgedOn?: string
    }
  }
}

export const QUERY_ONBOARDING_BANK_ACCOUNT = gql`
  query ${ONBOARDING_BANK_ACCOUNT_QUERY_NAME} {
    onboardingBankAccount {
      initialValues
    }
  }
`

export const CREATE_BANK_ACCOUNT_MUTATION_NAME = 'createBankAccount';

export const MUTATION_CREATE_BANK_ACCOUNT = gql`
  mutation(
    $nickname: String!
    $country: CountryCode!
    $currency: Currency!
    $bankAccountId: ID,
    $accountNumber: String
    $sortCode: String
    $bankCode: String
    $bsbCode: String
    $cbu: String
    $clabeCode: String
    $ifsCode: String
    $swiftCode: String
    $iban: String
    $accountHolderName: String
    $accountHolderType: String
    $accountType: String
    $bankName: String
    $institutionNumber: String
    $transitNumber: String
    $routingNumber: String
    $fingerprint: String
    $last4: String
  ) {
    ${CREATE_BANK_ACCOUNT_MUTATION_NAME}(
      input: {
        nickname: $nickname
        country: $country
        currency: $currency
        accountNumber: $accountNumber
        bankAccountId: $bankAccountId
        sortCode: $sortCode
        bankCode: $bankCode
        bsbCode: $bsbCode
        cbu: $cbu
        clabeCode: $clabeCode
        ifsCode: $ifsCode
        swiftCode: $swiftCode
        iban: $iban
        accountHolderName: $accountHolderName
        accountHolderType: $accountHolderType
        accountType: $accountType
        bankName: $bankName
        institutionNumber: $institutionNumber
        transitNumber: $transitNumber
        routingNumber: $routingNumber
        fingerprint: $fingerprint
        last4: $last4
      }
    ) {
      id
      nickname
      createdAt
      updatedAt
      details {
        id
        bankAccountId
        accountHolderName
        accountHolderType
        accountType
        accountTypeAcknowledgedOn
        bankName
        country
        currency
        routingNumber
        accountNumber
        fingerprint
        last4
        validAt
        invalidAt
      }
    }
  }
`

export const UPDATE_BANK_ACCOUNT_MUTATION_NAME = 'updateBankAccount';

export const MUTATION_UPDATE_BANK_ACCOUNT = gql`
  mutation(
    $bankAccountId: ID!,
    $country: CountryCode!
    $currency: Currency!
    $accountNumber: String!
    $accountHolderName: String
    $accountHolderType: String
    $accountType: String
    $bankName: String
    $institutionNumber: String
    $transitNumber: String
    $routingNumber: String
    $fingerprint: String
    $last4: String
  ) {
    ${UPDATE_BANK_ACCOUNT_MUTATION_NAME}(
      input: {
        bankAccountId: $bankAccountId
        country: $country
        currency: $currency
        accountNumber: $accountNumber
        accountHolderName: $accountHolderName
        accountHolderType: $accountHolderType
        accountType: $accountType
        bankName: $bankName
        institutionNumber: $institutionNumber
        transitNumber: $transitNumber
        routingNumber: $routingNumber
        fingerprint: $fingerprint
        last4: $last4
      }
    ) {
      id
      nickname
      createdAt
      updatedAt
      details {
        id
        bankAccountId
        accountHolderName
        accountHolderType
        accountType
        accountTypeAcknowledgedOn
        bankName
        country
        currency
        routingNumber
        accountNumber
        fingerprint
        last4
        validAt
        invalidAt
      }
    }
  }
`

export const UPDATE_BANK_ACCOUNT_TYPE_ACKNOWLEDGED_ON_MUTATION_NAME = 'updateBankAccountTypeAcknowledgedOn';

export const MUTATION_UPDATE_BANK_ACCOUNT_TYPE_ACKNOWLEDGED_ON = gql`
  mutation(
    $bankAccountId: ID!
  ) {
    ${UPDATE_BANK_ACCOUNT_TYPE_ACKNOWLEDGED_ON_MUTATION_NAME}(
      input: {
        bankAccountId: $bankAccountId
      }
    ) {
      id
      nickname
      createdAt
      updatedAt
      details {
        id
        bankAccountId
        accountHolderName
        accountHolderType
        accountType
        accountTypeAcknowledgedOn
        bankName
        country
        currency
        routingNumber
        accountNumber
        fingerprint
        last4
        validAt
        invalidAt
      }
    }
  }
`
