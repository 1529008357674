import { createContext, useCallback, useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import useQueryWithErrors from 'hooks/useQueryWithErrors';

type queryResType = {
  supportedCountries: SupportedCountryType[];
}

const supportedCountriesOperation = 'SupportedCountries';

const QUERY_SUPPORTED_COUNTRIES = gql`
  query ${supportedCountriesOperation}($locale: String) {
    supportedCountries(locale: $locale) {
        alpha2Code,
        emojiFlag,
        name,
        subdivisions {
            name,
            code
        },
        currencySymbol,
        symbolPrefix,
        currencyCode,
        phoneCountryCode,
        supportedCurrencies {
            symbol,
            code,
            symbolPrefix
        }
    }
  }
`

type SupportedCountryType = {
  alpha2Code: string,
  emojiFlag: string,
  name: string,
  subdivisions: {
    name: string,
    code: string
  }[],
  currencySymbol: string,
  symbolPrefix: boolean,
  currencyCode: string,
  phoneCountryCode: string,
  supportedCurrencies: {
    symbol: string,
    code: string,
    symbolPrefix: boolean
  }[]
}

type supportedCountryMapType = {
  [key: string]: SupportedCountryType
}

type SelectOptions = {
  value: string,
  description: string
}

type currencySymbolMapType = { [key: string]: { symbol: string, symbolPrefix: boolean} };

export type SupportedCountriesContextType = {
  supportedCountries: SupportedCountryType[];
  loading: boolean;
  currencySymbolMap: currencySymbolMapType;
  supportedCountryMap: supportedCountryMapType;
  formatCurrency: (value?: number, currency?: string, symbolPrefix?: boolean) => string;
  generateCountryOptions: () => SelectOptions[];
  generateCurrencyOptions: (selectedCountry: string) => SelectOptions[];
  generatePhoneCountryCodeOptions: () => SelectOptions[];
  generateStateOptions: (selectedCountry: string) => SelectOptions[];
}

const defaultCurrencySymbolMap: currencySymbolMapType = {
  usd: { symbol: '$', symbolPrefix: true }
}

const defaultContextValue: SupportedCountriesContextType = {
  supportedCountries: [],
  loading: true,
  currencySymbolMap: defaultCurrencySymbolMap,
  supportedCountryMap: {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formatCurrency: (value: number = 0, currency: string = '') => `${(value / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}`,
  generateCountryOptions: () => [],
  generateCurrencyOptions: () => [],
  generatePhoneCountryCodeOptions: () => [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  generateStateOptions: (selectedCountry: string) => []
}

export const SupportedCountriesContext = createContext<SupportedCountriesContextType>(defaultContextValue);

export const SupportedCountriesContextProvider = ({ children }: { children: JSX.Element }) => {
  const [supportedCountries, setSupportedCountries] = useState<SupportedCountryType[]>([]);
  const [supportedCountryMap, setSupportedCountryMap] = useState<supportedCountryMapType>({});
  const [currencySymbolMap, setCurrencySymbolMap] = useState<currencySymbolMapType>(defaultCurrencySymbolMap)

  const { data, loading } = useQueryWithErrors<queryResType>(
    supportedCountriesOperation,
    QUERY_SUPPORTED_COUNTRIES
  )

  /*
   * Cache Supported Countries in local storage so failed/slow requests to get the country data
   * doesn't block the application.
   */
  useEffect(() => {
    const remoteSupportedCountries = JSON.stringify(data?.supportedCountries);
    const localSupportedCountries = window.localStorage.getItem('supportedCountries');

    if (!loading && data?.supportedCountries && remoteSupportedCountries !== localSupportedCountries) {
      setSupportedCountries(data.supportedCountries);
      window.localStorage.setItem('supportedCountries', remoteSupportedCountries);
    } else {
      const cachedSupportedCountries = localSupportedCountries ? JSON.parse(localSupportedCountries) : [];
      setSupportedCountries(cachedSupportedCountries)
    }
  }, [data?.supportedCountries, loading]);

  /*
   * Build out the currencySymbolMap and supportCountryMap
   */
  useEffect(() => {
    if (supportedCountries) {
      const newCurrencySymbolMap = defaultCurrencySymbolMap;
      const newSupportedCountryMap: supportedCountryMapType = {};

      supportedCountries.forEach((country) => {
        if (!newCurrencySymbolMap[country.currencyCode]) {
          newCurrencySymbolMap[country.currencyCode] = { symbol: country.currencySymbol, symbolPrefix: country.symbolPrefix }
        }

        if (!newSupportedCountryMap[country.alpha2Code]) {
          newSupportedCountryMap[country.alpha2Code] = country;
        }
      })

      setCurrencySymbolMap(newCurrencySymbolMap);
      setSupportedCountryMap(newSupportedCountryMap);
    }
  }, [supportedCountries]);

  /*
   * Format currency given the value (in pennies) and the currency code
   *
   * Example:
   *   formatCurrency(10000, 'cad') => $100.00
   */
  const formatCurrency = useCallback((value: number = 0, currency: string = '', symbolPrefix: boolean = true) => {
    const currencySymbol = currencySymbolMap[currency] || '';
    const res = `${(value / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}`

    return symbolPrefix ? `${currencySymbol.symbol}${res}` : `${res} ${currencySymbol.symbol}`
  }, [currencySymbolMap]);

  /* Form Option Generators */

  const generateCountryOptions = useCallback(() => (
    supportedCountries.map((country) => (
      {
        value: country.alpha2Code,
        description: `${country.emojiFlag} ${country.name}`
      }
    ))
  ), [supportedCountries]);

  const generateCurrencyOptions = useCallback((selectedCountry: string) => {
    const country = supportedCountries.find((country) => country.alpha2Code === selectedCountry)

    if (!country) {
      return [];
    }

    return (
      country.supportedCurrencies.map((currency) => ({
        value: currency.code,
        description: `${currency.symbol} ${currency.code}`
      }))
    );
  }, [supportedCountries]);

  const generatePhoneCountryCodeOptions = useCallback(() => (
    supportedCountries.map((country) => (
      {
        value: country.phoneCountryCode,
        description: `${country.emojiFlag} ${country.name} ${country.phoneCountryCode}`
      }
    ))
  ), [supportedCountries]);

  const generateStateOptions = useCallback((selectedCountry: string) => {
    const country = supportedCountries.find((country) => country.alpha2Code === selectedCountry)

    if (!country) {
      return [];
    }

    return (
      country.subdivisions.map((subdivision) => ({
        value: subdivision.code,
        description: subdivision.name
      }))
    );
  }, [supportedCountries]);

  return (
    <SupportedCountriesContext.Provider
      value={{
        supportedCountries,
        loading,
        currencySymbolMap,
        supportedCountryMap,
        formatCurrency,
        generateCountryOptions,
        generateCurrencyOptions,
        generatePhoneCountryCodeOptions,
        generateStateOptions
      }}
    >
      {children}
    </SupportedCountriesContext.Provider>
  );
};
