import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  QUERY_PAYMENT_INVITATION_BY_ID,
  GET_PAYMENT_INVITATION_BY_ID
} from 'graphql/queries/PaymentInvitationQueries';
import { PaymentInvitationType } from 'graphql/types/PaymentInvitationTypes';
import useQueryWithErrors from './useQueryWithErrors';

const usePaymentInvitation = (
  id: string | null | undefined
) => {
  const navigate = useNavigate();
  const [paymentInvitationId, setPaymentInvitationId] = useState<string | null>(null);

  const redirectHome = () => navigate('/', { replace: true });

  useEffect(() => {
    if (!id) {
      redirectHome();
    } else {
      setPaymentInvitationId(id);
    }
  }, [id]);

  const queryResults = useQueryWithErrors<{ getPaymentInvitationById: PaymentInvitationType }>(
    GET_PAYMENT_INVITATION_BY_ID,
    QUERY_PAYMENT_INVITATION_BY_ID(paymentInvitationId || ''),
    {
      skip: !paymentInvitationId || paymentInvitationId.length === 0
    }
  )

  useEffect(() => {
    if (queryResults.error ||
      (queryResults.data?.getPaymentInvitationById?.details.status &&
        queryResults.data.getPaymentInvitationById.details.status !== 'created')) {
      redirectHome();
    }
  }, [queryResults])

  return {
    ...queryResults,
    paymentInvitation: queryResults.data?.getPaymentInvitationById,
    validDetails: queryResults.data?.getPaymentInvitationById?.details
  };
};

export default usePaymentInvitation;
